// import React from "react";
// import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
// import {
//   FaUsers,
//   FaGift,
//   FaWhatsapp,
//   FaCalendarAlt,
//   FaCashRegister,
//   FaTruckLoading,
//   FaMobileAlt,
//   FaIdCardAlt,
//   FaChartArea,
//   FaMoneyBillAlt,
//   FaSignature,
// } from "react-icons/fa";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FaFileInvoice } from "react-icons/fa6";

// const Service = () => {
//   const cardsData = [
//     {
//       icon: <FaWhatsapp color="green" size={40} />,
//       header: "אוטומציות בווטסאפ",
//       text: "התממשקות מלאה לווטסאפ, תזכורות ואישורי הגעה אוטומטיים",
//     },
//     {
//       icon: <FaUsers color="orange" size={40} />,
//       header: "ניהול צוות עובדים",
//       text: "ניהול משמרות ביומן, שעון נוכחות, דוחות, וניהול פשוט של צוות העובדים",
//     },
//     {
//       icon: <FaCashRegister color="steelblue" size={40} />,
//       header: "קופה + סליקת אשראי",
//       text: "חיוב והנפקת חשבוניות דיגיטליות – מערכת חיוב מלאה ומקצועית",
//     },
//     {
//       icon: <FaCalendarAlt color="orange" size={40} />,
//       header: "יומן",
//       text: "ניהול יומן לקוחות – אפליקציית יומן מתקדמת שתארגן לכם את הלוז",
//     },
//     {
//       icon: <FaFileInvoice size={40} color="steelblue" />,
//       header: "הצעות מחיר",
//       text: "אפשרות להקים הצעות מחיר ללקוחות בצורה קלה ומהירה, מה שמוסיף מקצועיות ומשפר את חוויית הלקוח",
//     },
//     {
//       icon: <FaIdCardAlt color="orange" size={40} />,
//       header: "הקמת סדרות טיפולים",
//       text: "תכנון ניהול והקמת סדרות טיפולים ללקוח.מעקב אחר טיפולים בצורה יעילה ומסודרת",
//     },
//     {
//       icon: <FaMobileAlt color="steelblue" size={40} />,
//       header: "תורים אונליין 24/7",
//       text: "לקוחות יכולים לקבוע תורים באינטרנט באופן עצמאי, מה שמקל עליכם ועליהם. אל דאגה, רק השעות הפנויות יוצגו, כך שהתורים הקיימים לא יהיו גלויים.",
//     },
//     {
//       icon: <FaTruckLoading color="orange" size={40} />,
//       header: "רכש ומלאי",
//       text: "ניהול רכש, מעקב מלאי בזמן אמת, ניהול ספקים, וניהול חכם של פריטים ומלאי",
//     },
//     {
//       icon: <FaMoneyBillAlt size={40} color="steelblue" />,
//       header: "תזרים אמצעי תשלום",
//       text: "אפשרות לראות את התזרים הכספי של העסק לפי אמצעי תשלום שונים – מזומן, אשראי, המחאות, העברות בנקאיות ועוד, כדי לנהל את הכספים בצורה יעילה ומדויקת.",
//     },
//     {
//       icon: <FaChartArea size={40} color="orange" />,
//       header: "דוחות וסטטיסטיקות",
//       text: "קבלת דוחות וסטטיסטיקות מפורטות על מכירות, הכנסות, נוכחות עובדים ועוד, כדי שתוכל/י לעקוב אחרי הביצועים העסקיים ולקבל החלטות מבוססות נתונים",
//     },
//     {
//       icon: <FaSignature color="steelblue" size={40} />,
//       header: "טפסים דיגיטליים",
//       text: "ממשק לניהול טפסים דיגיטליים בצורה מאובטחת ונוחה. המערכת מאפשרת לך לבנות טפסים אישיים וייחודיים המותאמים במיוחד לעסק שלך",
//     },

//     {
//       icon: <FaGift size={40} color="orange" />,
//       header: "גיפטקארד",
//       text: "אפשרות למכור ולהשתמש בכרטיסי גיפטקארד, מה שמוסיף אפשרות הכנסה נוספת ונותן שירות נוסף ללקוחות שלך",
//     },
//   ];

//   return (
//     <div className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30">
//       <div className="text-center sc-mb-60">
//         <h2 className="heading-title">שנה דיגיטלית את מקום העבודה שלך</h2>
//       </div>
//       <Row className="no-gutters justify-content-center">
//         {cardsData.map((card, index) => (
//           <Col
//             md={3}
//             key={index}
//             className="mb-3 d-flex justify-content-center h-100 sc-md-height-200 sc-pb-20 sc"
//           >
//             <Card
//               style={{
//                 flex: 1,
//                 height: "200px", // Adjusted height
//                 borderRadius: 20,
//                 boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
//                 justifyContent: "center",
//               }}
//             >
//               <CardHeader className="text-center">{card.icon}</CardHeader>
//               <CardBody className="text-center">
//                 <h4 className="card-title">{card.header}</h4>
//                 <p className="card-text">{card.text}</p>
//               </CardBody>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </div>
//   );
// };

// export default Service;
import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import {
  FaUsers,
  FaGift,
  FaWhatsapp,
  FaCalendarAlt,
  FaCashRegister,
  FaTruckLoading,
  FaMobileAlt,
  FaIdCardAlt,
  FaChartArea,
  FaMoneyBillAlt,
  FaSignature,
} from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa6";

import "bootstrap/dist/css/bootstrap.min.css";

const Service = () => {
  // Inline CSS for demo purposes; move into your stylesheet in practice.
  const customStyles = (
    <style>{`
      /* Container tweaks */
      .sc-service-section-area {
        background: linear-gradient(135deg, #f9f9f9 0%, #ffffff 100%);
        border-radius: 20px;
        padding: 50px 20px;
      }
      
      /* Heading styling */
      .sc-service-section-area h2 {
        color: #222;
        font-weight: 600;
        margin-bottom: 2rem;
      }

      /* Card hover glow & general styling */
      .glowing-card {
        border-radius: 20px;
        transition: transform 0.3s, box-shadow 0.3s;
        background: linear-gradient(135deg, #ffffff, #f3f3f3);
        overflow: hidden;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .glowing-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
      }

      /* Remove default CardHeader background/border, center content */
      .glowing-card-header {
        background: none;
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* Card body text styling */
      .glowing-card-body {
        text-align: center;
        color: #444;
      }

      /* Icon glow animation on hover */
      .glowing-icon:hover {
        animation: pulse 1s infinite;
      }

      /* Pulse keyframes for icons */
      @keyframes pulse {
        0% {
          filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0));
        }
        50% {
          filter: drop-shadow(0 0 10px rgba(0, 153, 255, 0.7));
        }
        100% {
          filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0));
        }
      }
    `}</style>
  );

  const cardsData = [
    {
      icon: <FaWhatsapp color="green" size={40} />,
      header: "אוטומציות בווטסאפ",
      text: "התממשקות מלאה לווטסאפ, תזכורות ואישורי הגעה אוטומטיים",
    },
    {
      icon: <FaUsers color="orange" size={40} />,
      header: "ניהול צוות עובדים",
      text: "ניהול משמרות ביומן, שעון נוכחות, דוחות, וניהול פשוט של צוות העובדים",
    },
    {
      icon: <FaCashRegister color="steelblue" size={40} />,
      header: "קופה + סליקת אשראי",
      text: "חיוב והנפקת חשבוניות דיגיטליות – מערכת חיוב מלאה ומקצועית",
    },
    {
      icon: <FaCalendarAlt color="orange" size={40} />,
      header: "יומן",
      text: "ניהול יומן לקוחות – אפליקציית יומן מתקדמת שתארגן לכם את הלוז",
    },
    {
      icon: <FaFileInvoice size={40} color="steelblue" />,
      header: "הצעות מחיר",
      text: "הקמת הצעות מחיר ללקוחות בצורה קלה ומהירה, מה שמוסיף מקצועיות ומשפר את חוויית הלקוח",
    },
    {
      icon: <FaIdCardAlt color="orange" size={40} />,
      header: "הקמת סדרות טיפולים",
      text: "תכנון וניהול סדרות טיפולים ללקוח. מעקב אחר טיפולים בצורה יעילה ומסודרת",
    },
    {
      icon: <FaMobileAlt color="steelblue" size={40} />,
      header: "תורים אונליין 24/7",
      text: "לקוחות יכולים לקבוע תורים באינטרנט באופן עצמאי, רק בשעות הפנויות שלכם. תורים קיימים נשארים חסויים.",
    },
    {
      icon: <FaTruckLoading color="orange" size={40} />,
      header: "רכש ומלאי",
      text: "ניהול רכש, מעקב מלאי בזמן אמת, ניהול ספקים, וניהול חכם של פריטים ומלאי",
    },
    {
      icon: <FaMoneyBillAlt size={40} color="steelblue" />,
      header: "תזרים אמצעי תשלום",
      text: "מעקב תזרים כספי לפי אמצעי תשלום – מזומן, אשראי, המחאות, העברות בנקאיות ועוד.",
    },
    {
      icon: <FaChartArea size={40} color="orange" />,
      header: "דוחות וסטטיסטיקות",
      text: "קבלת דוחות וסטטיסטיקות מפורטות על מכירות, הכנסות, נוכחות עובדים ועוד, להחלטות מבוססות נתונים",
    },
    {
      icon: <FaSignature color="steelblue" size={40} />,
      header: "טפסים דיגיטליים",
      text: "ממשק לניהול טפסים דיגיטליים מאובטחים. בניית טפסים אישיים המותאמים לעסק שלך",
    },
    {
      icon: <FaGift size={40} color="orange" />,
      header: "גיפטקארד",
      text: "אפשרות למכור ולהשתמש בכרטיסי גיפטקארד – מספק הזדמנות הכנסה ושירות נוסף ללקוחות",
    },
  ];

  return (
    <div className="sc-service-section-area sc-pt-210 sc-md-pt-90 sc-pb-140 sc-md-pb-50 sc-pb-30">
      {customStyles}
      <div className="text-center sc-mb-60">
        <h2 className="heading-title">שנה דיגיטלית את מקום העבודה שלך</h2>
      </div>
      <Row className="no-gutters justify-content-center">
        {cardsData.map((card, index) => (
          <Col
            md={3}
            key={index}
            className="mb-3 d-flex justify-content-center h-100 sc-md-height-200 sc-pb-20"
          >
            <Card className="glowing-card">
              <CardHeader className="glowing-card-header">
                <div className="glowing-icon">{card.icon}</div>
              </CardHeader>
              <CardBody className="glowing-card-body">
                <h5>{card.header}</h5>
                <p>{card.text}</p>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Service;

// export default Apps;
import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Fade } from "react-reveal";

const Apps = () => {
  const containerStyle = {
    padding: "50px 20px",
    backgroundColor: "#f7f7f7",
  };

  const textSectionStyle = {
    textAlign: "center",
    paddingLeft: "20px",
  };

  const headingStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#000",
    marginBottom: "15px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.5",
    marginBottom: "20px",
    color: "#555",
  };

  const trialButtonStyle = {
    backgroundColor: "#ff4081",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "50px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  };
  const handleWhatsAppClick = () => {
    const phonenumber = "0544311942";
    const whatsappUrl = `https://web.whatsapp.com/send?phone=+972${phonenumber}&text=${encodeURIComponent(
      "היי,אשמח לנסות את האפליקציה ל 14 יום ניסיון"
    )}`;

    window.open(whatsappUrl, "_blank");
  };
  return (
    <section style={containerStyle}>
      <div className="container">
        <div className="row">
          <div className="apps-area d-flex align-items-center">
            <Fade right>
              <div style={{ flex: 1 }}>
                <div style={textSectionStyle}>
                  <h2 style={headingStyle}>
                    פתרון ענן חכם לניהול מכוני יופי וקוסמטיקה
                    <br />
                    שדרגו את ניהול העסק שלכם ללא מאמץ!
                  </h2>
                  <p style={paragraphStyle}>
                    תיהנו מתכונות מתקדמות כמו ניהול לקוחות, יומן תורים דיגיטלי,
                    ניהול מלאי ושעון נוכחות. בנוסף, קבלו דוחות מותאמים אישית
                    לניתוח נתונים וקבלת החלטות מושכלות. עם הפתרון שלנו, תוכלו
                    להעניק ללקוחות שלכם חוויה בלתי נשכחת ולייעל את תהליכי העבודה
                    שלכם.
                  </p>
                  <button
                    style={trialButtonStyle}
                    onClick={handleWhatsAppClick}
                  >
                    התנסו עם 14 ימי ניסיון
                    <FaWhatsapp style={{ marginLeft: "5px" }} color="lime" />
                  </button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apps;
